import Component from '@averyano/core/src/classes/Component';
import GSAP from 'gsap';
import each from 'lodash/each';
import { isAncestorOrSelf } from '../utils/utils';

export default class Lightbox extends Component {
	constructor() {
		super({
			element: '.lightbox',
			elements: {
				bg: '.lightbox__bg',

				figures: '.lightbox__figure',
				images: '.lightbox__image',
				videos: '.lightbox__video',

				sources: '.lightbox__video source',

				lbCurrent: '.lightbox__figure--curr',

				videoSource: '.lightbox__video source',
				cross: '.lightbox__cross',

				prevArrow: '.lightbox__arrow--left',
				nextArrow: '.lightbox__arrow--right',
				arrows: '.lightbox__arrow',

				counter: {
					title: '.lightbox__title',
					subtitle: '.lightbox__subtitle',
					in: '.lightbox__counter--in .lightbox__counter-text--inner',
					out: '.lightbox__counter--out .lightbox__counter-text--inner',
				},
			},
		});

		this.allItems = [];
		this.isDragging = false;
		this.isVisible = false;
		this.timer = null;

		this.x = {
			start: 0,
			end: 0,
		};
		this.y = {
			start: 0,
			end: 0,
		};
		this.time = {
			start: 0,
			end: 0,
		};
		this.distance = 0;
		this.isTouch = false;
	}

	create(items) {
		super.createComponent();
		// items is NodeList, convert to array
		this.allItems = Array.prototype.slice.call(items);
		this.elements.counter.out.textContent = this.allItems.length;
		console.log(this.allItems);

		this.addEventListeners();
	}

	show() {
		GSAP.fromTo(
			this.elements.figures,
			{
				autoAlpha: 0,
				yPercent: 100,
			},
			{
				autoAlpha: 1,
				yPercent: 0,
				duration: 1.25,
				ease: 'expo.out',
			}
		);
	}

	getType(index) {
		// allItems is NodeList, convert to element
		const element = this.allItems[index];
		return element.getAttribute('data-type') || 'image';
	}

	getTitle(index) {
		// allItems is NodeList, convert to element
		const element = this.allItems[index];
		return `${element.getAttribute('data-title')} (${element.getAttribute(
			'data-year'
		)})`;
	}
	getSubtitle(index) {
		// allItems is NodeList, convert to element
		const element = this.allItems[index];
		return element.getAttribute('data-subtitle');
	}

	getSrc({
		activeIndex,
		imageElement,
		videoElement,
		sourceElement,
		titleElement,
		subtitleElement,
	}) {
		let typeIndex = activeIndex;
		if (activeIndex > this.allItems.length - 1) typeIndex = 0;
		if (activeIndex < 0) typeIndex = this.allItems.length - 1;

		const type = this.getType(typeIndex);
		// console.log(type);
		// console.log(this.allItems[typeIndex]);
		// .awards__item__title=title
		// .awards__item__subtitle=subtitle
		if (titleElement) titleElement.textContent = this.getTitle(typeIndex);
		if (subtitleElement)
			subtitleElement.textContent = this.getSubtitle(typeIndex);

		// elementFigure is parent it has both lightbox__image and lightbox__video
		const src = this.allItems[typeIndex].getAttribute('href');

		if (type === 'video') {
			imageElement.classList.add('lightbox__image--hidden');
			videoElement.classList.remove('lightbox__video--hidden');

			sourceElement.src = src;
			videoElement.load();
		} else if (type === 'image') {
			imageElement.classList.remove('lightbox__image--hidden');
			videoElement.classList.add('lightbox__video--hidden');
			imageElement.src = src;
		}
	}

	setActiveImage(item) {
		// this.allItems is a node list
		// item is a single node
		this.activeIndex = this.allItems.indexOf(item);

		let src = '';
		// console.log(this.allItems, this.activeIndex);

		this.getSrc({
			activeIndex: this.activeIndex - 1,
			imageElement: this.elements.images[0],
			videoElement: this.elements.videos[0],
			sourceElement: this.elements.sources[0],
		});
		this.getSrc({
			activeIndex: this.activeIndex,
			imageElement: this.elements.images[1],
			videoElement: this.elements.videos[1],
			sourceElement: this.elements.sources[1],
			titleElement: this.elements.counter.title,
			subtitleElement: this.elements.counter.subtitle,
		});
		this.getSrc({
			activeIndex: this.activeIndex + 1,
			imageElement: this.elements.images[2],
			videoElement: this.elements.videos[2],
			sourceElement: this.elements.sources[2],
		});

		this.updateCounter();
	}

	updateCounter() {
		this.elements.counter.in.textContent = this.activeIndex + 1;
	}

	clickAction(e) {
		if (isAncestorOrSelf(e.target, this.elements.cross)) {
			this.close();
		} else if (isAncestorOrSelf(e.target, this.elements.prevArrow)) {
			GSAP.killTweensOf(this.elements.figures);
			this.slideToPrevious();
		} else if (isAncestorOrSelf(e.target, this.elements.nextArrow)) {
			GSAP.killTweensOf(this.elements.figures);
			this.slideToNext();
		}
	}

	slideToNext() {
		each(this.elements.videos, (video) => {
			video.pause();
		});

		if (this.activeIndex === this.allItems.length - 1) {
			this.activeIndex = 0;
		} else {
			this.activeIndex += 1;
		}

		this.setActiveImage(this.allItems[this.activeIndex]);
	}

	slideToPrevious() {
		each(this.elements.videos, (video) => {
			video.pause();
		});

		if (this.activeIndex === 0) {
			this.activeIndex = this.allItems.length - 1;
		} else {
			this.activeIndex -= 1;
		}

		this.setActiveImage(this.allItems[this.activeIndex]);

		// GSAP.fromTo(
		// 	this.elements.figures,
		// 	{
		// 		xPercent: 0,
		// 	},
		// 	{
		// 		xPercent: 100,
		// 		duration: 1,
		// 		ease: 'power4.out',
		// 		onComplete: () => {
		// 			this.setActiveImage(this.allItems[this.activeIndex - 1]);
		// 			GSAP.fromTo(
		// 				this.elements.figures,
		// 				{
		// 					xPercent: -100,
		// 				},
		// 				{
		// 					xPercent: 0,
		// 					duration: 1,
		// 					ease: 'power4.out',
		// 				}
		// 			);
		// 		},
		// 	}
		// );
	}

	showArrows() {
		GSAP.killTweensOf(this.elements.arrows);
		GSAP.to(this.elements.arrows, {
			opacity: 1,
			duration: 1,
			ease: 'power4.out',
			overwrite: true,
		});

		if (this.timer) clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.hideArrows();
		}, 5000);
	}

	hideArrows() {
		GSAP.to(this.elements.arrows, {
			opacity: 0,
			duration: 1,
			ease: 'power4.out',
		});
	}

	open() {
		this.element.classList.remove('lightbox--hidden');
		this.isVisible = true;
		this.showArrows();
		this.$emit('stopScroll');
		this.$emit('hideMenu');
	}

	close() {
		this.element.classList.add('lightbox--hidden');

		each(this.elements.images, (image) => {
			image.src = '';
		});

		each(this.elements.videos, (video) => {
			video.pause();
		});

		each(this.elements.sources, (source) => {
			source.src = '';
		});

		this.isVisible = false;
		this.hideArrows();
		this.$emit('startScroll');
		this.$emit('showMenu');
	}

	onTouchDown(e) {
		if (
			e.target.classList.contains('lightbox__inner') ||
			e.target.classList.contains('lightbox__arrow')
		) {
			this.isTouch = true;

			this.time.start = Date.now();
			this.x.start = e.touches ? e.touches[0].clientX : e.clientX;
			this.y.start = e.touches ? e.touches[0].clientY : e.clientY;
			this.startUpdating();
		}
	}

	onTouchMove(e) {
		if (!this.isTouch) return;

		const x = e.touches ? e.touches[0].clientX : e.clientX;
		const y = e.touches ? e.touches[0].clientY : e.clientY;
		const yDistanceFromStart = Math.abs(y - this.y.start);

		this.distance = x - this.x.start;

		// If it's more of a horizontal swipe than a vertical swipe, prevent vertical scrolling.
		if (Math.abs(this.distance) > yDistanceFromStart) {
			e.preventDefault();
		}

		if (Math.abs(this.distance) > 2) {
			this.touchDirection = this.distance < 0 ? -1 : 1;
		}
	}

	onTouchUp(e) {
		if (!this.isTouch) return;
		this.isTouch = false;

		const x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
		const y = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;

		this.y.end = y;
		this.x.end = x;

		this.time.end = Date.now();
		// console.log(`Swipe time: ${this.time.end - this.time.start}ms`);
		const xDistance = Math.abs(this.x.end - this.x.start);
		// console.log(`Distance: ${xDistance}`);

		if (
			this.time.end - this.time.start < 1000 &&
			this.time.end - this.time.start > 55 &&
			xDistance > 40
		) {
			e.preventDefault(); // Prevents potential subsequent events like clicks

			this.touchDirection === 1 ? this.slideToPrevious() : this.slideToNext();
			this.time.end = this.time.start = 0;

			this.timer = setTimeout(this.stopUpdating.bind(this), 2000);
		}

		GSAP.killTweensOf(this.elements.lbCurrent);
		this.distance = 0;
	}

	startUpdating() {
		if (!this.isUpdating) {
			this.isUpdating = true;
			this.update();
		}
	}

	stopUpdating() {
		this.isUpdating = false;
		this.target = null;
	}

	update() {
		if (!this.isUpdating) return;

		GSAP.to(this.elements.lbCurrent, {
			x: this.distance * 0.2,
			duration: 1,
			ease: 'power4.out',
		});
		requestAnimationFrame(this.update.bind(this));
	}

	addEventListeners() {
		this.element.addEventListener('click', (e) => {
			this.clickAction(e);
		});

		window.addEventListener('keydown', (e) => {
			if (this.isVisible === false) return;

			if (e.key === 'Esc' || e.keyCode === 27) {
				this.close();
			} else if (e.key === 'ArrowLeft' || e.keyCode === 37) {
				this.slideToPrevious();
			} else if (e.key === 'ArrowRight' || e.keyCode === 39) {
				this.slideToNext();
			}
		});

		window.addEventListener('mousemove', (e) => {
			if (this.isVisible === false) return;
			this.showArrows();
		});

		window.addEventListener('mousedown', this.onTouchDown.bind(this));
		window.addEventListener('mousemove', this.onTouchMove.bind(this));
		window.addEventListener('mouseup', this.onTouchUp.bind(this));

		window.addEventListener('touchstart', this.onTouchDown.bind(this));
		window.addEventListener('touchmove', this.onTouchMove.bind(this));
		window.addEventListener('touchend', this.onTouchUp.bind(this));
	}

	removeElementFromDOM() {
		this.elements.cross.removeEventListener('click', (e) => {
			this.clickAction(e);
		});

		window.removeEventListener('keydown', (e) => {
			if (this.isVisible === false) return;

			if (e.key === 'Esc' || e.keyCode === 27) {
				this.close();
			} else if (e.key === 'ArrowLeft' || e.keyCode === 37) {
				this.slideToPrevious();
			} else if (e.key === 'ArrowRight' || e.keyCode === 39) {
				this.slideToNext();
			}
		});

		window.removeEventListener('mousemove', (e) => {
			if (this.isVisible === false) return;
			this.showArrows();
		});

		window.removeEventListener('mousedown', this.onTouchDown.bind(this));
		window.removeEventListener('mousemove', this.onTouchMove.bind(this));
		window.removeEventListener('mouseup', this.onTouchUp.bind(this));

		window.removeEventListener('touchstart', this.onTouchDown.bind(this));
		window.removeEventListener('touchmove', this.onTouchMove.bind(this));
		window.removeEventListener('touchend', this.onTouchUp.bind(this));
	}
}
