import GSAP from 'gsap';
import Component from '@averyano/core/src/classes/Component';

export default class ExperienceNav extends Component {
	constructor() {
		super({
			element: '.cover__nav',
			elements: {
				btnPlay: '.cover__nav__btn--play',
				btnForward: '.cover__nav__btn--forward',
				btnBackward: '.cover__nav__btn--backward',
				btnSpeed: '.cover__nav__btn--speed',
				btnSpeedText: '.cover__nav__btn--speed span',
				// btnInfo: '.cover__nav__btn--info',
				btnLock: '.cover__nav__btn--lock',

				infoWrapper: '.cover__nav-elements--info',
				infoTitle: '.cover__nav__info__title',
				infoLink: '.cover__nav__info__link',
			},
		});

		this.isPlaying = true;
		this.speed = 1;
		this.speeds = [0.5, 1, 2];
		this.isLocked = false;
		this.motion = null;
		this.create();
	}

	create() {
		this.addEventListeners();
		// this.toggleLock();
	}

	playPause() {
		this.isPlaying = !this.isPlaying;
		this.elements.btnPlay.classList.toggle('is-playing');

		if (this.isPlaying) {
			this.$emit('play');
		} else {
			this.$emit('pause');
		}
		console.log(this.isPlaying);
	}

	changeSpeed() {
		this.speed =
		this.speeds[(this.speeds.indexOf(this.speed) + 1) % this.speeds.length];
		this.elements.btnSpeedText.textContent = `${this.speed}x`;
		this.$emit('speed', this.speed);
	}
	
	toggleLock() {
		this.isLocked = !this.isLocked;
		this.elements.btnLock.classList.toggle('active');
		// this.elements.infoWrapper.classList.toggle('is-visible');
		this.element.classList.toggle('is-locked');
		// this.$emit('toggleInfo');
	}

	changeProject(project) {
		this.elements.infoTitle.innerHTML = project.name;
		this.elements.infoLink.href = project.be;
	}

	clearForwardBackward() {
		this.elements.btnForward.classList.remove('active');
		this.elements.btnBackward.classList.remove('active');
		this.element.classList.remove('disabled');
	}

	addEventListeners() {
		this.elements.btnPlay.addEventListener('click', this.playPause.bind(this));
		this.elements.btnForward.addEventListener('click', () => {
			this.motion = 'forward';
			this.element.classList.add('disabled');
			this.elements.btnForward.classList.add('active');
			this.$emit('forward');
		});
		this.elements.btnBackward.addEventListener('click', () => {
			this.motion = 'backward';
			this.element.classList.add('disabled');
			this.elements.btnBackward.classList.add('active');
			this.$emit('backward');
		});
		this.elements.btnSpeed.addEventListener('click', () => this.changeSpeed());
		this.elements.btnLock.addEventListener('click', this.toggleLock.bind(this));
	}

	removeEventListeners() {
		this.elements.btnPlay.removeEventListener(
			'click',
			this.playPause.bind(this)
		);
		this.elements.btnForward.removeEventListener('click', () => {
			this.motion = 'forward';
			this.element.classList.add('disabled');
			this.elements.btnForward.classList.add('active');
			this.$emit('forward');
		});
		this.elements.btnBackward.removeEventListener('click', () => {
			this.motion = 'backward';
			this.element.classList.add('disabled');
			this.elements.btnBackward.classList.add('active');
			this.$emit('backward');
		});
		this.elements.btnSpeed.removeEventListener('click', () =>
			this.changeSpeed()
		);
		this.elements.btnLock.removeEventListener(
			'click',
			this.toggleLock.bind(this)
		);
	}

	destroy() {
		this.removeEventListeners();
	}
}
