import GSAP from 'gsap';

import ImageHoverEffect from '../../components/@avery-image-hover-effect';
import AboutSection from './AboutSection';
import CoverSection from './CoverSection';

import Page from '@averyano/core/src/classes/Page';

import each from 'lodash/each';
import SelectionTextSection from './SelectionTextSection';
export default class HomePage extends Page {
	showUiBound = this.showUi.bind(this);
	enterTheMainPageBound = this.enterTheMainPage.bind(this);

	constructor(el, callback) {
		super({
			element: el,
			elements: {},
		});

		this.callback = callback;
		this.id = 'home';
		this.isCreated = false;

		this.isTablet = matchMedia('(max-width: 1024px)').matches;
	}

	create() {
		if (this.template != this.id) return;

		if (!this.isReady) super.createComponent();

		this.uiElements = [
			...document.querySelectorAll('.ui__element'),
			// document.querySelector('.cover__nav'),
		];

		if (!this.isTablet)
			this.timer = setTimeout(() => {
				GSAP.to(this.uiElements, {
					autoAlpha: 0,
					duration: 0.5,
					ease: 'power2.out',
				});
			}, 3500);

		window.globalAnalyticsInstance.trackEvent('Page View', {
			action: 'Visit',
			label: '/works',
		});

		if (!this.isCreated) {
			this.components = {
				cover: new CoverSection(),
				// selection: new ImageHoverEffect({
				// 	element: '.selection',
				// 	elements: {
				// 		figure: '.selection__figure',
				// 		content: [
				// 			{
				// 				image: '#selection-designs',
				// 				button: '.selection__button--design',
				// 			},
				// 			{
				// 				image: '#selection-websites',
				// 				button: '.selection__button--website',
				// 			},
				// 		],
				// 	},
				// 	activeClass: 'selection__image--active',
				// }),
				// about: new AboutSection(),
				// selectionText: new SelectionTextSection(),
			};
			this.isCreated = true;
		}

		// Create components
		each(this.components, (component) => {
			console.log(component);
			component.create();
			// component.addEventListeners();
		});

		this.addEventListeners();

		console.log(`🔼 ${this.id} is created`);
	}

	show() {
		if (this.template != this.id) return;

		// this.components.cover.button.revealButton();
	}

	hide() {
		return new Promise((resolve) => {
			this.destroy();

			GSAP.to(this.element, {
				autoAlpha: 0,
				onComplete: resolve,
			});
		});
	}

	enterTheMainPage(e) {
		// e.preventDefault();
		const eventObject = e;
		eventObject.target.href = '/main';
		if (e.key === 'Enter') {
			this.callback(eventObject);
		}
	}

	showUi() {
		if (this.isTablet) return;

		GSAP.to(this.uiElements, {
			autoAlpha: 1,
			duration: 0.5,
			ease: 'power2.out',
		});

		if (this.timer) clearTimeout(this.timer);

		this.timer = setTimeout(() => {
			const navmenu = document.querySelector('.nav__menu');
			// if navmenu has class .open do something
			if (navmenu.classList.contains('open')) return;

			GSAP.to(this.uiElements, {
				autoAlpha: 0,
				duration: 0.5,
				ease: 'power2.out',
			});
		}, 3500);
	}

	addEventListeners() {
		window.addEventListener('keyup', this.enterTheMainPageBound);

		window.addEventListener('mousemove', this.showUiBound);

		each(this.components, (component) => {
			component.addEventListeners();
		});
	}

	removeEventListeners() {
		window.removeEventListener('keyup', this.enterTheMainPageBound);

		window.removeEventListener('mousemove', this.showUiBound);

		each(this.components, (component) => {
			component.removeEventListeners();
		});

		if (this.timer) clearTimeout(this.timer);
		GSAP.to(this.uiElements, {
			autoAlpha: 1,
			duration: 0.5,
			ease: 'power2.out',
		});
		console.log('kill');
	}

	destroy() {
		super.destroy();
		this.removeEventListeners();

		if (this.timer) clearTimeout(this.timer);

		GSAP.to(this.uiElements, {
			autoAlpha: 1,
			duration: 0.5,
			ease: 'power2.out',
		});

		each(this.components, (component) => {
			component.destroy();
		});

		// Removes scroll trigger instances
		const scrolltriggerElements = document.querySelectorAll('.pin-spacer');
		each(scrolltriggerElements, (pinSpacer) => {
			const parent = pinSpacer.parentElement;

			while (pinSpacer.firstChild) {
				parent.appendChild(pinSpacer.firstChild);
			}

			parent.removeChild(pinSpacer);
		});
	}

	update() {
		if (
			this.components &&
			this.components.selection &&
			this.components.selection.isReady
		)
			this.components.selection.update();
	}
}
