import Component from '@averyano/core/src/classes/Component';

import Reveal from '../../../animations/Reveal';
import each from 'lodash/each';
import map from 'lodash/map';

import { clamp, insertAfter, lerp } from '../../../utils/utils';
import GSAP from 'gsap';
import ExpertiseList from './ExpertiseList';

export default class ExpertiseSection extends Component {
	constructor() {
		super({
			element: '.expertise',
			elements: {
				sectionWrapper: '.expertise .section__wrapper',
				sectionTitle: '.section__title',
				expertiseWrapper: '.expertise__wrapper',
				header: '.expertise__title',
				headerSubtitle: '.expertise__subtitle',
				figure: '.expertise__item__figure',
				figureOverlap: '.expertise__figure__overlap',

				images: '.expertise__item__image',
				popup: '.expertise__popup',
				arrowRight: '.expertise__popup__item--right',
				arrowLeft: '.expertise__popup__item--left',

				bottomLine: '.expertise__bottom__list__line',
				popUpCenter: '.expertise__popup__item--center',

				itemsList: '.expertise__bottom__list',
				listItems: '.expertise__bottom__list__item',
				popupEye: '.expertise__popup__eye',
			},
		});

		this.isAnimatingTxt = false;

		GSAP.set(this.elements.figureOverlap, {
			scaleX: 0,
			transformOrigin: 'left',
		});
		this.timer = null;
		this.media = window.matchMedia('(max-width: 1024px)');
		this.direction = 1; // 1 right, -1 left
		// Touch related
		this.x = {
			start: 0,
			end: 0,
		};

		this.y = {
			start: 0,
			end: 0,
		};

		this.time = {
			start: 0,
			end: 0,
		};
		this.isTouch = false;
		this.touchDirection = 1;
		this.touchTargetClass = '';

		this.eyeVisible = false;

		// Else
		this.elements.button = document.getElementById('expertise-get-in-touch');
		this.option = {
			current: 1,
		};
		this.option.previous = 1;

		this.currentIndex = 0;

		this.isFullscreen = false;
		window.isFullscreen = this.isFullscreen;
		this.isRevealed = false;

		this.currentIndex = 0; // 0 Light, 1 Pro, 2 Supervision
		this.currentType = 'light';

		this.bottomPopupText = '';
		this.bottomPopupTimer = null;
		this.bottomPopupCounter = 0;

		this.disableDoubleClick = false;

		this.bounds = this.element.getBoundingClientRect();
		this._boundTop = this.elements.sectionWrapper.getBoundingClientRect().top;
		this.boundsArrows = {
			start: this._boundTop - this.bounds.height,
			light: this._boundTop,
			pro: this._boundTop + this.bounds.height,
			supervision: this._boundTop + this.bounds.height * 2,
			end: this._boundTop + this.bounds.height * 3,
		};
	}

	getBounds() {
		this.bounds = this.element.getBoundingClientRect();

		const bounds = this.elements.sectionWrapper.getBoundingClientRect();

		console.log('BOUNDS TOP: ', this._boundTop);
		console.log('CURRENT SCROLL: ', window.scrollY);
		console.log(this.boundsArrows);
	}
	create() {
		super.createComponent();

		this.getBounds();
		this.createTimeline();
		this.createExpertiseList();

		this.addEventListeners();

		this.revealImage(0);
		// this.elements.bottomLine.style.transform = `scaleX(${0})`;

		this.setActiveItem(1);

		setTimeout(() => {
			this.onEnterShowAnimation();
			this.adjustBottomPopup();
		}, 1500);
	}

	onLeaveHideAnimation() {
		if (!this.isRevealed) return;

		this.expertiseList.hideList(this.direction);
		this.isRevealed = false;
	}
	onEnterShowAnimation() {
		if (this.isRevealed) return;

		this.expertiseList.showList(this.direction);
		this.expertiseList.replaceDescriptionContent(0, true);
		this.isRevealed = true;
	}

	createTimeline() {
		this.timeline = GSAP.timeline({
			scrollTrigger: {
				trigger: this.element,
				scrub: 1,
				pin: true,
				start: 'top top',
				end: () => '+=' + this.bounds.height * 0.25,
				markers: false,
				onEnter: () => {
					this.onEnterShowAnimation();
				},
				onLeaveBack: () => {
					this.onLeaveHideAnimation();
				},
				onUpdate: (self) => this.updateContent(self.progress),
				onEnterBack: () => {
					this.$emit('navChangeIndex', 1);
					GSAP.killTweensOf(this.elements.button);
					GSAP.to(this.elements.button, {
						autoAlpha: 0,
						duration: 0.5,
					});
				},
			},
		});
	}

	adjustBottomPopup() {
		if (this.bottomPopupTimer) clearTimeout(this.bottomPopupTimer);
		if (
			this.bottomPopupText === this.elements.header.textContent &&
			this.bottomPopupCounter < 5
		) {
			this.bottomPopupTimer = setTimeout(() => {
				// console.log(this.bottomPopupCounter);
				this.adjustBottomPopup();
				this.bottomPopupCounter++;
			}, 100);
			return;
		}

		const padding = this.media.matches ? 16 : 70 * 2;
		const headerWidth = this.elements.header.getBoundingClientRect().width;
		const width = this.media.matches ? Math.max(200, headerWidth) : headerWidth;
		let newWidth = 0;
		this.bottomPopupText = this.elements.header.textContent;

		GSAP.killTweensOf(this.elements.popUpCenter);
		if (this.media.matches) {
			newWidth = '80vw'; // mobile
		} else if (this.currentIndex === 0 || this.currentIndex === 1) {
			newWidth = 543;
		} else {
			newWidth = width + padding;
		}
		GSAP.to(this.elements.popUpCenter, {
			width: newWidth,
			duration: 0.5,
			ease: 'out.expo',
		});
	}

	hideBottomTxt() {
		let y = 0;
		let duration;
		if (this.direction === 1) {
			// forward
			y = 200;
		} else if (this.direction === -1) {
			y = -200;
		}

		if (this.media.matches) {
			duration = 1;
		} else {
			duration = 1;
		}

		return new Promise((resolve) => {
			if (this.isAnimatingTxt) return resolve();
			this.isAnimatingTxt = true;

			if (this.media.matches) {
				if (this.timer) clearTimeout(this.timer);
				this.timer = setTimeout(() => resolve(), 100);
			}
			// GSAP.to(this.elements.headerSubtitle, {
			// 	autoAlpha: 0,
			// 	duration: 0.4,
			// 	ease: 'out.expo',
			// });
			GSAP.fromTo(
				this.elements.header,
				{
					y: 0,
					opacity: 1,
				},
				{
					y: y,
					opacity: 0,
					duration: duration,
					ease: 'out.expo',
					onComplete: () => resolve(),
				}
			);
		}, 0);
	}

	showBottomTxt() {
		let y = 0;
		let duration;
		let delay = 0;
		if (this.media.matches) {
			duration = 1;
			delay = 0.2;
		} else {
			duration = 1.45;
		}

		if (this.direction === 1) {
			// forward
			y = -200;
		} else if (this.direction === -1) {
			y = 200;
		}
		return new Promise((resolve) => {
			GSAP.set(this.elements.header, {
				y: y,
			});

			GSAP.fromTo(
				this.elements.header,
				{
					y: y,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: duration,
					delay: delay,
					ease: 'out.expo',
					onComplete: () => {
						this.isAnimatingTxt = false;
						resolve();
					},
				}
			);
			// GSAP.to(
			// 	this.elements.headerSubtitle,
			// 	{
			// 		autoAlpha: 1,
			// 		duration: 0.9,
			// 		ease: 'out.expo',
			// 	},
			// 	'>'
			// );
		});
	}

	replaceBottomLineTxt() {
		this.media = window.matchMedia('(max-width: 1024px)');

		if (this.currentIndex === 0) {
			this.elements.header.textContent = this.media.matches
				? 'Light'
				: 'Design Light';
			this.elements.headerSubtitle.textContent = '£80 per m²';
		}
		if (this.currentIndex === 1) {
			this.elements.header.textContent = this.media.matches
				? 'Pro'
				: 'Design Pro';
			this.elements.headerSubtitle.textContent = '£120 per m²';
		}
		if (this.currentIndex === 2) {
			this.elements.header.textContent = this.media.matches
				? 'Supervision'
				: 'Design Supervision';
			// this.elements.headerSubtitle.textContent = '£40 per m²';
			this.elements.headerSubtitle.textContent = 'optionally';
		}
	}

	createExpertiseList() {
		this.expertiseList = new ExpertiseList();

		setTimeout(() => {
			this.onLeaveHideAnimation();
		}, 1000);
	}

	updateContent(progress) {
		if (progress === 1) {
			GSAP.to(this.elements.button, {
				autoAlpha: 1,
				duration: 1,
			});
		}
	}

	revealImage(i, onLeaveAnim = false, onEnterAnim = false) {
		let duration;
		if (this.media.matches) {
			duration = 0.6;
		} else {
			duration = 1;
		}

		if (onEnterAnim) {
			GSAP.to(this.elements.figureOverlap, {
				scaleX: 0,
				transformOrigin: 'left',
				duration: duration,
				ease: 'out.expo',
			});
			return;
		}
		if (onLeaveAnim) {
			GSAP.to(this.elements.figureOverlap, {
				scaleX: 1,
				transformOrigin: 'right',
				duration: duration,
				ease: 'out.expo',
			});
			return;
		}

		if (this.direction === -1) {
			GSAP.to(this.elements.figureOverlap, {
				scaleX: 1,
				transformOrigin: 'right',
				duration: duration,
				ease: 'out.expo',
				onComplete: () => {
					map(this.elements.images, (image) => {
						image.classList.remove('active');
					});
					this.elements.images[i].classList.add('active');
				},
			});
			GSAP.to(
				this.elements.figureOverlap,
				{
					scaleX: 0,
					transformOrigin: 'left',
					duration: duration,
					ease: 'out.expo',
				},
				'>'
			);
		} else if (this.direction === 1) {
			GSAP.to(this.elements.figureOverlap, {
				scaleX: 1,
				transformOrigin: 'left',
				duration: duration,
				ease: 'out.expo',
				onComplete: () => {
					map(this.elements.images, (image) => {
						image.classList.remove('active');
					});
					this.elements.images[i].classList.add('active');
				},
			});
			GSAP.to(
				this.elements.figureOverlap,
				{
					scaleX: 0,
					transformOrigin: 'right',
					duration: duration,
					ease: 'out.expo',
				},
				'>'
			);
		}
	}

	setActiveItem(i) {
		GSAP.killTweensOf(this.elements.listItems);
		GSAP.killTweensOf(this.elements.button);
		GSAP.killTweensOf(this.elements.bottomLineWrapper);
		GSAP.killTweensOf(this.elements.bottomLine);
		GSAP.killTweensOf(this.elements.header);
		GSAP.killTweensOf(this.elements.headerSubtitle);

		this.currentIndex = i;
		if (this.currentIndex === 0) this.currentType = 'light';
		else if (this.currentIndex === 1) this.currentType = 'pro';
		else if (this.currentIndex === 2) this.currentType = 'supervision';

		this.expertiseList.currentIndex = this.currentIndex;
		this.expertiseList.currentType = this.currentType;

		this.hideBottomTxt().then(() => {
			this.replaceBottomLineTxt();
			this.showBottomTxt();
		});
		this.revealImage(this.currentIndex);

		this.expertiseList
			.hideList(this.direction)
			.then(() => {
				this.expertiseList.replaceListContent();
				if (this.media.matches)
					this.elements.itemsList.scrollTo({ left: 0, behavior: 'smooth' });
			})
			.then(() => {
				this.bottomPopupCounter = 0;
				this.adjustBottomPopup();
				this.expertiseList.showList(this.direction);
				this.expertiseList.replaceDescriptionContent(0, true);
			});
	}

	showNav() {
		this.elements.navFullscreen.classList.add('active');
	}

	hideNav() {
		this.elements.navFullscreen.classList.remove('active');
	}

	goNext() {
		if (this.disableDoubleClick) return;

		this.direction = 1;
		this.disableDoubleClick = true;
		setTimeout(() => (this.disableDoubleClick = false), 1000);

		if (this.currentIndex === 0) {
			this.setActiveItem(1);
		} else if (this.currentIndex === 1) {
			this.setActiveItem(2);
		} else if (this.currentIndex === 2) {
			this.setActiveItem(0);
		}
	}

	goBack() {
		if (this.disableDoubleClick) return;

		this.direction = -1;
		this.disableDoubleClick = true;
		setTimeout(() => (this.disableDoubleClick = false), 1000);

		if (this.currentIndex === 0) {
			this.setActiveItem(2);
		} else if (this.currentIndex === 1) {
			this.setActiveItem(0);
		} else if (this.currentIndex === 2) {
			this.setActiveItem(1);
		}
	}

	hideEye() {
		this.eyeVisible = false;
		GSAP.to(this.elements.popup, {
			autoAlpha: 1,
			duration: 0.5,
			ease: 'out.expo',
		});

		GSAP.to(this.elements.arrowRight, {
			x: 0,
			yPercent: 0,
			duration: 0.75,
			scale: 1,
			rotate: 0,
			delay: 0.15,
			ease: 'out.expo',
		});
		GSAP.to(this.elements.arrowLeft, {
			x: -0,
			yPercent: 0,
			duration: 0.75,
			scale: 1,
			rotate: 0,
			ease: 'out.expo',
		});
		GSAP.to(this.elements.popUpCenter, {
			scale: 1,
			duration: 0.5,
			delay: 0.35,
			ease: 'out.expo',
		});

		GSAP.to(this.elements.popupEye, {
			autoAlpha: 0,
			scale: 0,
			rotate: -20,
			duration: 0.5,
			ease: 'out.expo',
		});
	}
	showEye() {
		this.eyeVisible = true;
		GSAP.to(this.elements.popup, {
			autoAlpha: 0,
			duration: 0.5,
			delay: 0.3,
			ease: 'out.expo',
		});

		GSAP.to(this.elements.arrowRight, {
			autoAlpha: 0,
			ease: 'out.expo',
		});
		GSAP.to(this.elements.arrowLeft, {
			autoAlpha: 0,
			ease: 'out.expo',
		});

		GSAP.to(this.elements.popUpCenter, {
			scale: 0,
			duration: 0.5,
			// delay: 0.35,
			ease: 'out.expo',
		});

		GSAP.to(this.elements.popupEye, {
			autoAlpha: 0.8,
			scale: 1,
			rotate: 0,
			duration: 0.5,
			delay: 0.5,
			ease: 'out.expo',
		});
	}

	onTouchDown(e) {
		if (
			!this.swipeIsDisabled &&
			e.target.classList.contains('expertise__item__figure')
		) {
			this.isTouch = true;

			this.time.start = Date.now();
			this.x.start = e.touches ? e.touches[0].clientX : e.clientX;
			this.y.start = e.touches ? e.touches[0].clientY : e.clientY;
		}
	}

	onTouchMove(e) {
		if (!this.isTouch) return;

		const x = e.touches ? e.touches[0].clientX : e.clientX;
		const y = e.touches ? e.touches[0].clientY : e.clientY;
		const yDistanceFromStart = Math.abs(y - this.y.start);

		this.distance = x - this.x.start;

		// If it's more of a horizontal swipe than a vertical swipe, prevent vertical scrolling.
		if (Math.abs(this.distance) > yDistanceFromStart) {
			e.preventDefault();
		}

		if (Math.abs(this.distance) > 2) {
			this.touchDirection = this.distance < 0 ? -1 : 1;
		}
	}

	onTouchUp(e) {
		if (!this.isTouch) return;
		this.isTouch = false;

		const x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
		const y = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;

		this.y.end = y;
		this.x.end = x;

		this.time.end = Date.now();
		console.log(`Swipe time: ${this.time.end - this.time.start}ms`);
		const yDistance = Math.abs(this.y.end - this.y.start);

		if (
			this.time.end - this.time.start < 5000 &&
			this.time.end - this.time.start > 90 &&
			yDistance < 100 &&
			e.target.classList.contains('expertise__item__figure')
		) {
			e.preventDefault(); // Prevents potential subsequent events like clicks

			this.touchDirection === 1 ? this.goBack() : this.goNext();
			this.time.end = this.time.start = 0;
		}
	}

	addEventListeners() {
		this.expertiseList.addEventListeners();
		this.elements.button = document.getElementById('expertise-get-in-touch');

		this.elements.button.addEventListener('click', () => {
			window.globalAnalyticsInstance.trackEvent('Button', {
				action: 'Click',
				label: 'Get in Touch',
			});
		});

		this.elements.arrowRight.addEventListener('click', () => {
			this.goNext();
		});
		this.elements.arrowLeft.addEventListener('click', () => {
			this.goBack();
		});

		window.addEventListener('mousedown', this.onTouchDown.bind(this));
		window.addEventListener('mousemove', this.onTouchMove.bind(this));
		window.addEventListener('mouseup', this.onTouchUp.bind(this));

		window.addEventListener('touchstart', this.onTouchDown.bind(this));
		window.addEventListener('touchmove', this.onTouchMove.bind(this));
		window.addEventListener('touchend', this.onTouchUp.bind(this));

		// this.elements.popUpCenter.addEventListener('click', () => {
		// 	!this.eyeVisible ? this.showEye() : this.hideEye();
		// });

		// this.elements.popupEye.addEventListener('click', () => {
		// 	!this.eyeVisible ? this.showEye() : this.hideEye();
		// });
	}

	removeEventListeners() {
		this.expertiseList.removeEventListeners();

		this.elements.button.removeEventListener('click', () => {
			window.globalAnalyticsInstance.trackEvent('Button', {
				action: 'Click',
				label: 'Get in Touch',
			});
		});

		window.removeEventListener('mousedown', this.onTouchDown.bind(this));
		window.removeEventListener('mousemove', this.onTouchMove.bind(this));
		window.removeEventListener('mouseup', this.onTouchUp.bind(this));

		window.removeEventListener('touchstart', this.onTouchDown.bind(this));
		window.removeEventListener('touchmove', this.onTouchMove.bind(this));
		window.removeEventListener('touchend', this.onTouchUp.bind(this));
	}

	destroy() {
		super.destroy();

		if (this.expertiseList) this.expertiseList.destroy();

		if (this.timeline) {
			this.timeline.kill();
			this.timeline = null;
		}

		this.option.previous = null;
		this.option.current = null;
	}

	onResize() {
		this.getBounds();
		this.media = window.matchMedia('(max-width: 1024px)');
		const height = document
			.querySelector('.nav__wrapper')
			.getBoundingClientRect().height;
		this.elements.sectionWrapper.style.paddingTop = height + 'px';
		this.elements.sectionTitle.style.height = height + 'px';

		this.adjustBottomPopup();

		const popupHeight = this.elements.popup.getBoundingClientRect().height;
		this.elements.popupEye.style.marginBottom = `${popupHeight / 4}px`;

		this.replaceBottomLineTxt();
	}
}
