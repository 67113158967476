import Component from '@averyano/core/src/classes/Component';

import Reveal from '../../animations/Reveal';
import each from 'lodash/each';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { insertAfter } from '../../utils/utils';
import GSAP from 'gsap';
import axios from 'axios';

export default class ContactSection extends Component {
	constructor() {
		super({
			element: '.contact',
			elements: {
				title: '.contact__title',
				image: '.contact__left__img',
				line: '.contact__line',
				paragraph: '.contact__paragraph',
				form: {
					el: '.contact__form__wrapper',
					container: '.contact__form__container',
					comment: '.contact__form__comment',
					textInputs: 'input[type="text"]',
					radioInputs: 'input[type="radio"]',
					button: '.contact__form__button',
					buttonText: '.contact__form__button__text',
					labels: '.list li label',

					details: '.contact__select__details',
					detailsParentElement: '.contact__form__group--select',
					detailsListEls: '.list li',
					icons: {
						wrapper: '.contact__form__button__icon',
						arrow: '.contact__form__button__icon--arrow',
						loading: '.contact__form__button__icon--loading',
						check: '.contact__form__button__icon--checkmark',
						cross: '.contact__form__button__icon--cross',
					},
				},
			},
		});
		this.isSending = false;
		this.contactEmail = 'hello@utkina-design.com';

		this.submitFormBound = this.submitForm.bind(this);
		this.handleClickBound = this.createNewRequest.bind(this);
		this.handleMouseEnterBound = this.activateButton.bind(this);
		this.handleMouseLeaveBound = this.deactivateButton.bind(this);
		// this.lang = window.lang;
	}

	create() {
		super.createComponent();
		this.createTimeline();

		this.addEventListeners();
	}

	createTimeline() {
		this.timeline = GSAP.timeline({
			scrollTrigger: {
				trigger: this.element,
				// start: 'top bottom',
				// end: 'top bottom',
				toggleActions: 'restart none none reverse',
				scrub: 1,
			},
		});

		this.timelineInstant = GSAP.timeline({
			defaults: { duration: 1, ease: 'out.expo' },
			scrollTrigger: {
				trigger: this.elements.title,
				// start: 'top bottom',
				// end: 'top bottom',
				toggleActions: 'restart none none reverse',
				onEnter: () => this.$emit('navChangeIndex', 3),
				onEnterBack: () => this.$emit('navChangeIndex', 3),
				// markers: true,
			},
		});

		this.timeline.fromTo(
			this.elements.image,
			{
				yPercent: 4,
			},
			{
				yPercent: -4,
				ease: 'power4.out',
			}
		);

		this.timelineInstant
			.fromTo(this.elements.title, { autoAlpha: 0 }, { autoAlpha: 1 })
			.fromTo(this.elements.line, { scaleX: 0 }, { scaleX: 1 }, '>-=0.5')
			.fromTo(
				this.elements.paragraph,
				{ autoAlpha: 0, y: 20 },
				{ autoAlpha: 1, y: 0 },
				'>-=0.5'
			)
			.fromTo(
				this.elements.form.container,
				{ autoAlpha: 0, y: 20 },
				{ autoAlpha: 1, y: 0 },
				'>-=1'
			)
			.fromTo(
				this.elements.form.button,
				{
					scaleX: 0,
				},
				{ scaleX: 1, duration: 1 },
				'>-=0.35'
			)
			.fromTo(
				[this.elements.form.buttonText, this.elements.form.icons.wrapper],
				{
					autoAlpha: 0,
				},
				{ autoAlpha: 1, duration: 1 },
				'>'
			)
			.fromTo(
				this.elements.form.comment,
				{ autoAlpha: 0 },
				{ autoAlpha: 1 },
				'>'
			);

		// ScrollTrigger.create({
		// 	trigger: this.element,
		// 	start: 'top center',
		// 	end: 'bottom bottom',
		// 	onEnter: () => this.$emit('navChangeIndex', 3),
		// 	onEnterBack: () => this.$emit('navChangeIndex', 3),
		// 	// markers: true,
		// });
	}

	animate(type) {
		// UI updates
		if (type === 'loading') {
			this.elements.form.button.style.cursor = 'not-allowed';
			this.elements.form.button.style.pointerEvents = 'none';
			this.isSending = true;

			GSAP.to(this.elements.form.icons.arrow, { autoAlpha: 0 });
			GSAP.fromTo(
				this.elements.form.icons.loading,
				{ autoAlpha: 0 },
				{ autoAlpha: 1 }
			);

			this.elements.form.buttonText.firstElementChild.innerHTML = `<span>${
				CONTACT_STATUSES[window.lang].sending
			}<span class="dotanim--1">.</span><span class="dotanim--2">.</span><span class="dotanim--3">.</span></span>`;
		}

		if (type === 'success') {
			GSAP.to(this.elements.form.icons.loading, { autoAlpha: 0 });
			GSAP.fromTo(
				this.elements.form.icons.check,
				{ autoAlpha: 0 },
				{ autoAlpha: 1 }
			);

			this.elements.form.button.classList.add('delivered');
			this.elements.form.icons.wrapper.classList.add('delivered');

			this.elements.form.buttonText.firstElementChild.innerHTML =
				CONTACT_STATUSES[window.lang].delivered;
			// this.lang === 'ua' ? 'Надіслано' : 'Delivered';
		}

		if (type === 'error') {
			GSAP.to(this.elements.form.icons.loading, { autoAlpha: 0 });
			GSAP.fromTo(
				this.elements.form.icons.cross,
				{ autoAlpha: 0 },
				{ autoAlpha: 1 }
			);
			this.elements.form.buttonText.firstElementChild.innerHTML =
				CONTACT_STATUSES[window.lang].error;
			// this.lang === 'ua' ? 'Помилка...' : 'Error...';
		}
	}

	async sendData(data) {
		try {
			await axios
				.post(
					'https://us-central1-daria-utkina-2023.cloudfunctions.net/api/submitform',
					data
				)
				.then(() => {
					this.animate('success');
					window.globalAnalyticsInstance.trackEvent('Contact', {
						action: 'Form Submission',
						label: 'Submitted',
					});
				});
		} catch (error) {
			console.error(error.message);
			this.animate('error');

			// Error text
			this.elements.form.container.style.position = 'relative';
			this.elements.form.container.insertAdjacentHTML(
				'beforeend',
				`<p id="contactform-error-text" class="contact__paragraph" style="text-align: center;position: absolute;opacity: 0;padding: 24px;">${
					`${
						CONTACT_STATUSES[window.lang].errormsg1
					}<a class="nav__email__link" style="font-weight:bold;" href="mailto:${
						this.contactEmail
					}">${this.contactEmail}</a>.<br>${
						CONTACT_STATUSES[window.lang].errormsg2
					}`
					// this.lang === 'ua'
					// 	? `Будь ласка, зв'яжіться зі мною по e-mail <a class="nav__email__link" href="mailto:${this.contactEmail}?subject=Design Services">${this.contactEmail}</a>. Перепрошую за незручності!`
					// 	: `Please contact me directly at <a class="nav__email__link" href="mailto:${this.contactEmail}">${this.contactEmail}</a>. Apologies for the inconvenience!`
				}</p>`
			);

			this.removeEventListeners();
			this.activateButton();
			this.elements.form.button.classList.add('error');

			window.globalAnalyticsInstance.trackEvent('Contact', {
				action: 'Form Submission',
				label: 'Error',
			});

			GSAP.fromTo(
				'#contactform-error-text',
				{ opacity: 0 },
				{
					opacity: 1,
					duration: 1.5,
					ease: 'expo.out',
					delay: 0.2,
				}
			);
		}
	}

	activateButton() {
		this.elements.form.buttonText.classList.add('active');
		this.elements.form.icons.wrapper.classList.add('active');
	}

	deactivateButton() {
		this.elements.form.buttonText.classList.remove('active');
		this.elements.form.icons.wrapper.classList.remove('active');
	}

	createNewRequest(e) {
		if (this.isSending) return;
		this.elements.form.el.requestSubmit();
	}
	submitForm(e) {
		e.preventDefault();
		this.animate('loading');

		// Get data from form and add it to an object
		const finalData = {};
		const formData = new FormData(this.elements.form.el);

		[...formData].map((info) => {
			finalData[info[0]] = info[1];
		});

		console.log(finalData);
		// Send the object via axios
		this.sendData(finalData);
	}

	selectRadio(e) {
		e.target.closest('.contact__select__details').removeAttribute('open');
	}

	hideClosestLabel(label) {
		GSAP.to(label, {
			yPercent: -250,
			duration: 0.3,
			fontSize: '12px',
			ease: 'power4.out',
		});
	}

	showClosestLabel(label) {
		const query = window.matchMedia('(max-width: 768px)');
		const sizeVal = query.matches ? '12px' : '16px';

		console.log(sizeVal);

		GSAP.to(label, {
			yPercent: -50,
			duration: 0.3,
			fontSize: sizeVal,
			ease: 'power4.out',
		});
	}

	addEventListeners() {
		this.elements.form.el.addEventListener('submit', this.submitFormBound);

		this.elements.form.button.addEventListener('click', this.handleClickBound);

		this.elements.form.button.addEventListener(
			'mouseenter',
			this.handleMouseEnterBound
		);
		this.elements.form.button.addEventListener(
			'mouseleave',
			this.handleMouseLeaveBound
		);

		each(this.elements.form.labels, (label) => {
			label.addEventListener('click', (e) =>
				e.target.closest('.contact__select__details').removeAttribute('open')
			);
		});

		each(this.elements.form.detailsParentElement, (selection) => {
			selection.addEventListener('click', (e) => {
				let target = e.target.firstElementChild;
				if (!target) return;

				if (target.classList.contains('contact__form__group__svgarrow')) {
					target = target.closest('.contact__select__details');
				}

				if (target && target.classList.contains('contact__select__details')) {
					each(this.elements.form.details, (detail) => {
						if (detail !== target) {
							detail.removeAttribute('open');
						}
					});
				}
			});
		});

		each(this.elements.form.textInputs, (input) => {
			input.addEventListener('input', ({ target }) => {
				const label = target
					.closest('.contact__form__group')
					.querySelector('label');

				if (target.value.length > 0) {
					this.hideClosestLabel(label);
				} else {
					this.showClosestLabel(label);
				}
			});
		});

		each(this.elements.form.detailsListEls, (el) => {
			el.addEventListener('click', ({ target }) => {
				// if type label
				if (target.tagName !== 'LABEL') return;
				const label = target
					.closest('.contact__form__group--select')
					.querySelector('.radios__title');

				this.hideClosestLabel(label);
			});
		});
	}

	removeEventListeners() {
		this.elements.form.el.removeEventListener('submit', this.submitFormBound);

		this.elements.form.button.removeEventListener(
			'click',
			this.handleClickBound
		);

		this.elements.form.button.removeEventListener(
			'mouseenter',
			this.handleMouseEnterBound
		);
		this.elements.form.button.removeEventListener(
			'mouseleave',
			this.handleMouseLeaveBound
		);

		each(this.elements.form.labels, (label) => {
			label.removeEventListener('click', (e) =>
				e.target.closest('.contact__select__details').removeAttribute('open')
			);
		});

		each(this.elements.form.detailsParentElement, (selection) => {
			selection.removeEventListener('click', (e) => {
				let target = e.target.firstElementChild;

				if (target.classList.contains('contact__form__group__svgarrow')) {
					target = target.closest('.contact__select__details');
				}

				if (target && target.classList.contains('contact__select__details')) {
					each(this.elements.form.details, (detail) => {
						if (detail !== target) {
							detail.removeAttribute('open');
						}
					});
				}
			});
		});

		each(this.elements.form.textInputs, (input) => {
			input.removeEventListener('input', ({ target }) => {
				const label = target
					.closest('.contact__form__group')
					.querySelector('label');

				if (target.value.length > 0) {
					this.hideClosestLabel(label);
				} else {
					this.showClosestLabel(label);
				}
			});
		});

		each(this.elements.form.detailsListEls, (el) => {
			el.removeEventListener('click', ({ target }) => {
				// if type label
				if (target.tagName !== 'LABEL') return;
				const label = target
					.closest('.contact__form__group--select')
					.querySelector('.radios__title');

				console.log(label, el);
				this.hideClosestLabel(label);
			});
		});
	}

	destroy() {
		super.destroy();

		if (this.timeline) {
			this.timeline.kill();
			this.timeline = null;
		}
	}

	onResize() {}
}
