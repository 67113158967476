import Experience from './components/Canvas/Experience';
import Navigation from './components/Navigation';
import Loader from './components/@avery-loader/Loader';
import CookieBanner from './components/CookieBanner';

import { Detection } from './classes/Detection';
import createGlobalAnalytics from './classes/GlobalAnalytics';
const firebaseConfig = {
	apiKey: 'AIzaSyC-jLcAJH75IMU6tc1Q4kOXzU4H0jWhTss',
	authDomain: 'daria-utkina-2023.firebaseapp.com',
	projectId: 'daria-utkina-2023',
	storageBucket: 'daria-utkina-2023.appspot.com',
	messagingSenderId: '887696507797',
	appId: '1:887696507797:web:fda41cbb34883cead12c9c',
	measurementId: 'G-6H0E9Z8TSR',
};

createGlobalAnalytics(firebaseConfig, Detection);
import HomePage from './pages/Home';
import MainPage from './pages/Main';

import Lenis from '@studio-freight/lenis';

import { debounce } from './utils/utils';
import { checkWebpSupport, requestIdleCallbackPolyfill } from './utils/utils';

import ScrollTrigger from 'gsap/all';

import NotFound from './pages/NotFound';
import Footer from './components/Footer';
import normalizeWheel from 'normalize-wheel';
import { create } from 'lodash';
import TermsPage from './pages/Terms';
import Preloader from './components/@avery-loader/Preloader';

import each from 'lodash/each';

import Avery from '@averyano/core/src';

class App {
	constructor() {
		this.isCreated = false;

		this.create();
	}

	async create() {
		window.scrollTo(0, 0);

		// lang parameter on the html
		window.lang = document.documentElement.lang;
		console.log(window.lang);

		// Scroll
		// this.scroll = new Lenis({
		// 	// infinite: true,
		// });

		// this.scroll.scrollTo('top');

		// Canvas
		this.createPreloader();
		this.createContent();
		this.init();
		this.createNavigation();
		this.registerNodeEvents();

		// Other
		this.addEventListeners();
		this.update();
	}

	createPreloader() {
		this.preloader = new Preloader();
	}

	createNavigation() {
		this.navigation = new Navigation();
		this.navigation.show();
		this.navigation.closeAll();
	}

	createContent() {
		// Pages
		this.mainDiv = document.querySelector('.main-div');

		this.home = new HomePage('.cover', (e) => this.loader.clickLink(e));
		this.main = new MainPage('.main');
		this.terms = new TermsPage('.terms');
		this.notfound = new NotFound('.notfound');

		this.pages = [
			{ page: this.home, url: ['/', '/works'] },
			{ page: this.main, url: '/main' },
			{ page: this.terms, url: '/terms' },
			{ page: this.notfound, url: '/404' },
		];

		this.pageLength = this.mainDiv.getBoundingClientRect().height;
		this.isCreated = true;

		this.cookieBanner = new CookieBanner();
		this.footer = new Footer();
	}

	init() {
		this.avery = new Avery({
			detection: Detection,
			loader: {
				pages: this.pages,
				preloader: this.preloader,
				onLeave: async () => {
					if (this.scroll) this.scroll.destroy();

					if (this.experience) {
						this.experience.destroy();
						this.experience = null;
					}

					this.navigation.closeAll();
					await this.preloader.show();
				},

				beforeEnter: async () => {
					console.log(this.avery.loader.router.content.images);
					this.preloader.selectorChildren.images =
						this.avery.loader.router.content.images;
					this.preloader.createComponent();
					this.preloader.createLoader(this.avery.loader.template);
				},

				afterEnter: () => {
					console.log(`Enterred ${this.avery.loader.template}`);

					if (this.avery.loader.template === 'home') {
						this.experience = new Experience('.webgl');
						document.title = 'Utkina Design — Works';
					} else {
						// this.scroll = new Lenis({
						// 	easing: (t) => 1 - Math.pow(1 - t, 3),
						// });
					}
				},
				onPreloaded: () => {
					console.log('%c Preloaded');
					if (this.avery.loader.template === 'home') {
						document.body.classList.add('refresh');
						this.navigation.element.classList.add('nav--cover');
						this.experience.updateImages(() => {
							this.preloader.hide();
							this.navigation.updateNav(this.avery.loader.template);
							this.show();
						});
					} else {
						document.body.classList.remove('refresh');
						this.navigation.element.classList.remove('nav--cover');
						this.preloader.hide();
						this.navigation.updateNav(this.avery.loader.template);
						this.show();
					}
				},
			},
			analytics: {
				firebase: firebaseConfig,
			},
		});

		this.id = document.querySelector('[data-page]').dataset.page;

		if (this.id === 'home') {
			this.experience = new Experience('.webgl');
			this.preloader.createLoader('home');
		}

		if (this.id === 'main') {
			// this.scroll = new Lenis({
			// 	easing: (t) => 1 - Math.pow(1 - t, 3),
			// });
			this.preloader.createLoader('main');
		}

		if (this.id === 'terms') {
			this.scroll = new Lenis({
				easing: (t) => 1 - Math.pow(1 - t, 3),
			});
			this.preloader.createLoader('terms');
		}

		if (this.id === '404') {
			this.preloader.createLoader('notfound');
		}

		this.avery.start();
		console.log(this.avery);
	}

	registerNodeEvents() {
		this.avery.$on('openMenu', () => {
			this.navigation.openMenu();
		});

		this.avery.$on('closeMenu', () => {
			this.navigation.closeMenu();
		});

		this.avery.$on('showMenu', () => {
			this.navigation.show();
		});
		this.avery.$on('hideMenu', () => this.navigation.hide());
		this.avery.$on('stopScroll', () => {
			document.documentElement.style.overflow = 'hidden';
		});
		this.avery.$on('startScroll', () => {
			document.documentElement.style.overflow = 'auto';
		});

		this.avery.$on('scrollTo', (e) => {
			if (this.scroll) this.scroll.scrollTo(e);
			else {
				const element = document.querySelector(e);

				element.scrollIntoView({
					behavior: 'smooth',
				});
			}
		});
		this.avery.$on('navigate', (url) => {
			console.log(this.avery);
			this.avery.loader.onChange({ url: url });
		});
		// this.avery.$on('stopScroll', () => this.scroll.stop());
		// this.avery.$on('startScroll', () => this.scroll.start());
	}

	onPreloaded() {}

	show() {
		if (this.avery.loader.template === 'home') {
			document.body.classList.add('refresh');
			this.experience.show();
			this.home.show();
		}

		// if (this.experience) this.experience.show();
	}

	update(time) {
		if (this.scroll) {
			this.scroll.raf(time);
		}

		if (this.experience && this.experience.isReady) this.experience.update();
		if (this.home && this.home.update) this.home.update();
		if (this.main && this.main.update) this.main.update();

		this.frame = window.requestAnimationFrame(this.update.bind(this));
	}

	addEventListeners() {
		console.log('add', this);
		window.addEventListener('resize', debounce(this.onResize.bind(this))); // runs on the next frame

		window.addEventListener('wheel', this.onWheel.bind(this));

		window.addEventListener('mousedown', this.onTouchDown.bind(this));
		window.addEventListener('mousemove', this.onTouchMove.bind(this));
		window.addEventListener('mouseup', this.onTouchUp.bind(this));

		window.addEventListener('touchstart', this.onTouchDown.bind(this));
		window.addEventListener('touchmove', this.onTouchMove.bind(this));
		window.addEventListener('touchend', this.onTouchUp.bind(this));

		window.addEventListener('keydown', this.onKeyDown.bind(this));
		window.addEventListener('keyup', this.onKeyUp.bind(this));
	}

	touchClassCheck({ target }) {
		const touchEls = [
			'cookies__button',
			'cookies__wrapper',
			'cookies__header',
			'cookies__title',
			'cookies__icon',
			'cookies__description',
			'cookies__description__link',
			'cookies__settings__wrapper',
			'toggle__switch',
			'toggle__switch__title',
			'cookies__buttons',
			'nav__menu',
			'nav__menu__line',
			'nav__content__link--inner',
			'nav__menu open',
			'nav__logo',
			'nav__wrapper',
			'circle__wrapper',
			'circle__button__text',
			'preloader',
			'preloader__bg',
			'preloader__logo__text',
			'preloader__loadingbar',
			'cover__nav__btn',
			'cover__nav__btn svg',
			'cover__nav__btn span',
			'cover__nav__play-icon',
			'cover__nav__pause-icon',
			'cover__nav-elements--info',
			'cover__nav__info__title',
			'cover__nav__info--box',
			'cover__nav__icon',
			'cover__nav__icon path',
			'cover__nav__icon rect',
			'cover__nav__text',
		];
		let result = false;

		each(touchEls, (el) => {
			if (target.classList.contains(el)) return (result = true);
		});

		return result;
	}

	onTouchDown(event) {
		if (this.experience && this.experience.onTouchDown) {
			if (this.touchClassCheck(event)) return;
			this.experience.onTouchDown(event);
		}
	}

	onTouchMove(event) {
		if (this.experience && this.experience.onTouchMove) {
			this.experience.onTouchMove(event);
		}
	}

	onTouchUp(event) {
		if (this.experience && this.experience.onTouchUp) {
			if (this.touchClassCheck(event)) return;
			this.experience.onTouchUp(event);
		}
	}

	onWheel(event) {
		const normalizedWheel = normalizeWheel(event);

		if (this.experience && this.experience.onWheel) {
			this.experience.onWheel(normalizedWheel);
		}
	}

	onKeyDown(event) {
		if (
			this.avery.loader.template === 'home' &&
			(event.key === 'Enter' || event.keyCode === 13)
		) {
			const btn = document.querySelector('.circle__button');
			if (btn) btn.click();
		}

		if (this.experience && this.experience.onKeyDown) {
			this.experience.onKeyDown(event);
		}
	}

	onKeyUp(event) {
		if (this.experience && this.experience.onKeyUp) {
			this.experience.onKeyUp(event);
		}
	}

	onResize() {
		window.isMobile = window.innerWidth < 768;

		if (this.main && this.main.isReady) this.main.onResize();
		if (this.experience && this.experience.isReady) this.experience.onResize();
	}
}

new App();
